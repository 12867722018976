import React, { useEffect, useState, useContext } from "react"
import { graphql, navigate, Link } from "gatsby"
import { API, graphqlOperation, Storage } from "aws-amplify"
import "./project.scss"
import SEO from "../components/seo"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import BackButton from "../images/backButton.png"
import "./mainTag.scss"

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    const image = await Storage.get("thumbnail" + props.image)
    setImage(image)
  }
  return image && <img width="70" height="70" src={image}></img>
}

const MainTagPage = props => {
  const mainTag = props.data.project.getMainTag
  console.log("maintag: ", mainTag)
  var allProjects = props.data.project.listProjects.items
  var allTags = []
  mainTag.tags.items.map(tag => {
    // const approvedProjects = tag.projects.items.filter(
    //   project => project.approved === true
    // )
    // tag.projects.items = approvedProjects
    // tag.projects.items = tag.projects.items.filter(
    //   project => project.approved === true
    // )
    // console.log("tag: ", tag)
    // console.log("all projects for this tag: ", tag.projects.items.map(project=>project.project.title))
    // console.log(
    //   "filtered: ",
    //   )
    tag.projects.items = tag.projects.items.filter(
      project => project.project.approved === true
    )
    allTags.push(tag)
    // tag.projects.items.map(projectLink => {
    //   allProjects.push(projectLink.project)
    // })
  })
  const allVisibleProjects = allProjects.filter(
    project => project.approved === true
  )
  // Filter for only approved
  // console.log("all visible projects: ", allVisibleProjects)
  // console.log("all tags: ", allTags)

  var tempMarkers = []
  var tempMarkerPositions = []
  allVisibleProjects.map(project => {
    if (project.location !== undefined) {
      tempMarkers.push({
        position: [project.location.lat, project.location.lon],
        text: project.title,
        id: project.id,
        onHubs: project.onHubs,
        featured: project.featured,
      })
      tempMarkerPositions.push([project.location.lat, project.location.lon])
    }
  })

  const [markers, setMarkers] = useState(tempMarkers)
  const [markerPositions, setMarkerPositions] = useState(tempMarkerPositions)
  const [activeId, setActiveId] = useState()

  function handleAccordionChange(tagId) {
    if (activeId === tagId) {
      setActiveId(null)
      updateMarkers(markers, markerPositions)
    } else {
      setActiveId(tagId)
      // console.log("hello")
      var tempMarkers = []
      var tempMarkerPositions = []
      const activeTag = allTags.filter(tag => tag.id === tagId)
      // console.log("active Tag: ", activeTag[0])
      activeTag[0].projects.items.map(project => {
        // console.log("project here: ", project)
        if (project.project.location !== undefined) {
          tempMarkers.push({
            position: [
              project.project.location.lat,
              project.project.location.lon,
            ],
            text: project.project.title,
            id: project.project.id,
            onHubs: project.project.onHubs,
            featured: project.project.featured,
          })
          tempMarkerPositions.push([
            project.project.location.lat,
            project.project.location.lon,
          ])
        }
      })
      updateMarkers(tempMarkers, tempMarkerPositions)
      // console.log("markers: ", tempMarkers)
      // console.log("positions: ", tempMarkerPositions)
    }
  }

  function updateMarkers(markers, positions) {
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
  }

  useEffect(() => {
    props.addMarker(markers)
    // props.flyToBounds(markerPositions)
    return () => {
      props.flyToBounds([])
    }
  }, [])

  return (
    <div className="MainTagPage padded">
      <SEO title={" | Browse | " + mainTag.name} />
      <Link to="/browse">
        <img className="back-button" src={BackButton}></img>
      </Link>
      <h1>{mainTag.name}</h1>
      <Accordion>
        {allTags.map((tag, index) => (
          <Card key={tag.id + index} className="main-tag-entry">
            <Accordion.Toggle
              // as={Card.Header}
              eventKey={tag.id}
              onClick={() => handleAccordionChange(tag.id)}
            >
              <h2>
                {tag.name}
                {/* <ContextAwareToggle eventKey={tag.id} onClick={decoratedOnClick} /> */}
              </h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={tag.id}>
              <Card.Body>
                {tag.projects.items.map((project, subIndex) => (
                  <div
                    key={project.project.id + tag.id + subIndex}
                    className="project"
                  >
                    <Link to={"/project/" + project.project.id}>
                      <ImageViewer image={project.project.image} />
                      <div>
                        <div className="title">{project.project.title}</div>
                        <div className="subtitle">
                          {project.project.subtitle}
                        </div>
                        <div className="description">
                          {project.project.description.substring(0, 55) + "..."}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  )
}

export const query = graphql`
  query($id: ID!) {
    project {
      getMainTag(id: $id) {
        name
        tags {
          items {
            name
            id
            projects {
              items {
                project {
                  id
                  onHubs
                  featured
                  approved
                  description
                  image
                  title
                  subtitle
                  location {
                    lat
                    lon
                  }
                }
              }
            }
          }
        }
      }
    }
    project {
      listProjects {
        items {
          onHubs
          id
          featured
          approved
          description
          image
          title
          location {
            lat
            lon
          }
        }
      }
    }
  }
`

export default MainTagPage
